export { default as logoImg } from './logo.png';
export { default as LogoSingle } from './LogoSingle.png';
export { default as notFoundImg } from './img-not-found.png';
export { default as networkImg } from './network-circle.png';
export { default as introSotalabsImg } from './intro-sotalabs.webp';
export { default as award1Img } from './award1.png';
export { default as award2Img } from './award2.png';
export { default as iconFeedbackImg } from './icon-feedback.png';
export { default as LogoBlocklenDark } from './logo-dark.svg';
export { default as LogoDarkWithoutBeta } from './logo-dark-without-beta.png';
export { default as ExpandMenu } from './expand-menu.svg';
export { default as CloseMenu } from './close-menu.svg';
export { default as Block01 } from './block-01.png';
export { default as Block02 } from './block-02.png';
export { default as Block03 } from './block-03.png';
export { default as Block04 } from './block-04.png';
export { default as Block05 } from './block-05.png';
export { default as Circle01 } from './circle-01.png';
export { default as Circle02 } from './circle-02.png';
export { default as CreateQueries } from './create-queries.png';
export { default as ExploreImg } from './ExploreImg.png';
export { default as TransparentRect } from './TransparentRect.png';
export { default as BookPlant } from './BookPlant.png';
export { default as RubikDesktopStart } from './3D_rubik_06A(100).gif';
export { default as RubikDesktopEnd } from './3D_rubik_06B(100).gif';
export { default as RubikMobileStart } from './3D_rubik_07A(100).gif';
export { default as RubikMobileEnd } from './3D_rubik_07B(100).gif';
export { default as Trigger01 } from './trigger-image-01.png';
export { default as Trigger02 } from './trigger-image-02.png';
export { default as Trigger03 } from './trigger-image-03.png';
export { default as TriggerBannerDesktop } from './trigger-banner-desktop.png';
export { default as TriggerBannerMobile } from './trigger-banner-mobile.png';
export { default as APIBannerDesktop } from './blocklens-api-banner-desktop.png';
export { default as APIBannerMobile } from './blocklens-api-banner-mobile.png';
export { default as IconArrowUpRight } from './u_arrow-up-right.png';
export { default as LogoAirbnb } from './LogoAirbnb.png';
export { default as LogoHubspot } from './LogoHubspot.png';
export { default as LogoGoogle } from './LogoGoogle.png';
export { default as LogoMicrosoft } from './LogoMicrosoft.png';
export { default as LogoWalmart } from './LogoWalmart.png';
export { default as LogoFedEx } from './LogoFedEx.png';
export { default as Blog01 } from './blog/Blocklens-On-chain-Data-Teller.jpg';
