import Link from 'next/link';
import Image from 'next/image';
import { FC, useEffect, useState } from 'react';
import styles from 'styles/components/Header.module.scss';
import { BLOCKLENS_LOGIN_URL } from 'utils/constant';
import { LogoDarkWithoutBeta, ExpandMenu, CloseMenu } from 'public/images';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface IHeader {
  isFixedHeader: boolean;
}

const Header: FC<IHeader> = ({ isFixedHeader }) => {
  const { asPath } = useRouter();
  const menus = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Blocklens Trigger',
      path: '/blocklens-trigger',
    },
    {
      name: 'Blocklens API',
      path: '/blocklens-api',
    },
    // {
    //   name: 'Pricing',
    //   path: '/pricing',
    // },
    {
      name: 'Blog',
      path: '/blog',
    },
  ];
  const [sticky, setSticky] = useState('');
  const [paddingY, setPaddingY] = useState(50);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [toggleMenu]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 1 ? 'is-sticky' : '';
    setSticky(stickyClass);

    if (scrollTop < 35 && paddingY > 35) {
      setPaddingY(paddingY - scrollTop);
    } else {
      setPaddingY(15);
    }
  };

  const _renderDesktop = () => {
    return (
      <Box
        py={`${paddingY}px`}
        mx={'auto'}
        maxW={'1240px'}
        w={'full'}
        display={{ base: 'none', lg: 'flex' }}
      >
        <Box
          h={'50px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Box h={'full'} mr={2.5}>
            <Link href={'/'} title="Home page">
              <Image
                className={styles['logo-icon']}
                src={LogoDarkWithoutBeta}
                alt="Blocklens logo"
              />
            </Link>
          </Box>
          {menus.map((menu, index) => (
            <Box
              key={index}
              px={0}
              mx={5}
              borderBottom={
                asPath.includes(menu.path) ? '1px solid #0060DB' : 'none'
              }
            >
              <Link
                href={menu.path}
                className={styles['menu-item']}
                rel="noreferrer"
                title={menu.name}
                style={{ color: asPath.includes(menu.path) ? '#0060DB' : '' }}
              >
                {menu.name}
              </Link>
            </Box>
          ))}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flex={'auto'}
        >
          {/*<Link*/}
          {/*  href={BLOCKLENS_LOGIN_URL}*/}
          {/*  target="_blank"*/}
          {/*  rel="noreferrer"*/}
          {/*  title="Click to log in Block Lens"*/}
          {/*  className={styles['btn-login']}*/}
          {/*>*/}
          {/*  Log In*/}
          {/*</Link>*/}
        </Box>
      </Box>
    );
  };

  const _renderMobile = () => {
    return (
      <>
        <Box
          display={{ lg: 'none' }}
          p={4}
          bg={'white'}
          shadow={'0px 2px 12px rgba(0, 0, 0, 0.05)'}
          zIndex={22}
          pos={'relative'}
        >
          <Box
            h={'50px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box h={'full'} mr={2.5}>
              <Link href={'/'} title="Home page">
                <Image
                  className={styles['logo-icon']}
                  src={LogoDarkWithoutBeta}
                  alt="Blocklens logo"
                />
              </Link>
            </Box>
            <Button
              colorScheme="none"
              onClick={() => setToggleMenu(!toggleMenu)}
              px={0}
              minW={'auto'}
            >
              {!toggleMenu ? (
                <Image src={ExpandMenu} alt="Blocklens logo" />
              ) : (
                <Image src={CloseMenu} alt="Blocklens logo" />
              )}
            </Button>
          </Box>
        </Box>
        <Box
          display={{ lg: 'none' }}
          className={`${styles['table-menu-mobile']} ${
            toggleMenu && styles['show-menu']
          }`}
        >
          <Box h={'full'} w={'full'} p={6} display={'flex'} flexDir={'column'}>
            {menus.map((menu, index) => (
              <Box key={index}>
                <Link
                  href={menu.path}
                  className={styles['menu-item']}
                  style={{
                    color: asPath.includes(menu.path) ? '#0060DB' : '',
                  }}
                  onClick={() => setToggleMenu(false)}
                >
                  {menu.name}
                  {asPath.includes(menu.path) && (
                    <ArrowForwardIcon
                      color={'#0060DB'}
                      ml={'auto'}
                      fontSize={'16px'}
                    />
                  )}
                </Link>
              </Box>
            ))}
            <Box flex={'auto'} display={'flex'} alignItems={'flex-end'}>
              <Link
                href={BLOCKLENS_LOGIN_URL}
                target="_blank"
                rel="noreferrer"
                title="Click to log in Block Lens"
                className={styles['btn-login']}
                onClick={() => setToggleMenu(false)}
              >
                Log In
              </Link>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      px={{ base: 0, lg: 4 }}
      pos={'fixed'}
      top={0}
      left={0}
      w={'full'}
      bg={'transparent'}
      zIndex={9999}
      transition={'all 0.25s linear'}
      className={styles[sticky]}
    >
      {_renderDesktop()}
      {_renderMobile()}
    </Box>
  );
};

export default Header;
