import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from 'styles/components/Footer.module.scss';
import { LogoDarkWithoutBeta } from 'public/images';
import { Box, Divider, Text } from '@chakra-ui/react';
import { BLOCKLENS_LOGIN_URL } from '../utils/constant';

const menus = [
  [
    {
      name: 'Blocklens Trigger',
      path: '/blocklens-trigger',
    },
    {
      name: 'Blocklens API',
      path: '/blocklens-api',
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
  ],
  [
    // {
    //   name: 'Docs',
    //   path: '/documentation',
    // },
    // {
    //   name: 'Pricing',
    //   path: '/pricing',
    // },
    {
      name: 'Blog',
      path: '/blog',
    },
    {
      name: 'Privacy Policy',
      path: '/privacy-policy',
    },
  ],
  [
    {
      name: 'About Us',
      path: '/about-us',
    },
    {
      name: 'Terms of Services',
      path: '/terms',
    },
    // {
    //   name: 'Press Kit',
    //   path: '/press-kit',
    // },
  ],
];

const menusMobile = [
  [
    {
      name: 'Blocklens Trigger',
      path: '/blocklens-trigger',
    },
    {
      name: 'Blocklens API',
      path: '/blocklens-api',
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'About Us',
      path: '/about-us',
    },
    // {
    //   name: 'Press Kit',
    //   path: '/press-kit',
    // },
  ],
  [
    // {
    //   name: 'Docs',
    //   path: '/documentation',
    // },

    // {
    //   name: 'Pricing',
    //   path: '/pricing',
    // },
    {
      name: 'Blog',
      path: '/blog',
    },
    {
      name: 'Privacy Policy',
      path: '/privacy-policy',
    },
    {
      name: 'Terms of Services',
      path: '/terms',
    },
  ],
];

const Footer = () => {
  return (
    <Box
      bg={'white'}
      pt={{ base: '222px', lg: '110px' }}
      px={4}
      overflow={'visible'}
    >
      <Box maxW={'1240px'} w={'full'} pos={'relative'} mx={'auto'}>
        <Box
          className={styles['banner-footer']}
          display={{ lg: 'flex' }}
          alignItems={'center'}
          justifyContent={'space-between'}
          h={{ lg: '220px' }}
          p={{ base: '40px 33px 50px 33px', lg: '62px 75px 50px 75px' }}
        >
          <Text
            as="h2"
            className={styles['title-banner']}
            mb={{ base: '30px', lg: 0 }}
          >
            Reach 10m+ Blockchain Users With Blocklens
          </Text>
          <Link
            href={'https://console.blocklens.io'}
            target="_blank"
            rel="noreferrer"
            title="Get Started"
            className={styles['btn-start-free']}
          >
            Get Started
          </Link>
        </Box>
        <Box
          pt={'55px'}
          pb={{ base: '30px', lg: '85px' }}
          display={{ lg: 'flex' }}
        >
          <Box
            flex={'auto'}
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            mb={{ base: '35px', lg: 0 }}
          >
            <Image
              className={styles['logo-icon']}
              src={LogoDarkWithoutBeta}
              alt="Blocklens logo"
            />
          </Box>

          <Box
            display={{ base: 'flex', lg: 'none' }}
            justifyContent={'space-between'}
            className={styles['menu-mobile']}
          >
            {menusMobile.map((item, index) => (
              <Box
                key={index}
                display={'flex'}
                justifyContent={`${index == 1 ? 'flex-end' : 'flex-start'}`}
              >
                <Box>
                  {item.map((menu, i) => (
                    <Box key={i} mb={'25px'}>
                      <Link
                        href={menu.path}
                        className={styles['text-link-menu']}
                        rel="noreferrer"
                        title={menu.name}
                      >
                        <span className={styles['text']}>{menu.name}</span>
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>

          <Box display={{ base: 'none', lg: 'flex' }}>
            {menus.map((column, index) => (
              <Box key={index}>
                {column.map((menu, i) => (
                  <Box
                    key={i}
                    ml={'100px'}
                    mr={'45px'}
                    className={styles['field-text']}
                  >
                    <span className={styles['dot']}></span>
                    <Link
                      href={menu.path}
                      className={styles['menu-item']}
                      rel="noreferrer"
                      title={menu.name}
                    >
                      <span className={styles['text']}>{menu.name}</span>
                    </Link>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
        <Divider />
        <Box
          w={'full'}
          h={{ base: '75px', lg: '88px' }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text className={styles['copy-right']}>
            <span>&copy;</span> 2023, Blocklens
          </Text>
          <Box display={'flex'}>
            <Link
              href={'https://twitter.com/blocklens_io'}
              target="_blank"
              rel="noreferrer"
              title="Click to follow"
              className={styles['link-social']}
            >
              Twitter
            </Link>
            <Link
              href={'https://discord.gg/ctnBrdhqad'}
              target="_blank"
              rel="noreferrer"
              title="Click to follow"
              className={styles['link-social']}
            >
              Discord
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
